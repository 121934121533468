import {
  REGISTRATION_PAGE,
  SIGNIN_PAGE,
  LANDING_PAGE,
  BODIAM_ACTIONS,
  TRIAGE_WRAPPER,
  MY_CLAIMS_PAGE,
  CONTACTS_PAGE,
  FAQ_PAGE,
  COOKIES_PAGE,
  DATAPROTECTION_PAGE,
  LEGALNOTICE_PAGE,
  CHECK_POLICY_COVER,
  UPLOAD_CLAIM_DOCUMENTS,
  PROFILE_DASHBOARD_PAGE,
  TASKLIST,
  REVIEW,
  COVER_PAGE,
    COVER_PAGE_FORM,
    COVER_PAGE_BYDATE,
  GENERATE_CERTIFICATE_SUCCESS
} from 'constants/constants';

import * as types from 'actions/actionTypes';
import { resetDecisionTree } from 'actions/decisionTreeActions';

import actionProvider from 'utils/action-provider/action-provider';
import { goTo } from './core/app.navigation';

export function gotoLandingMainPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: LANDING_PAGE };
}

export function gotoSignIn() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: SIGNIN_PAGE };
}

export function gotoRegistrationPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: REGISTRATION_PAGE };
}

export function gotoTriageWrapperClaim() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: TRIAGE_WRAPPER };
}

export function gotoClaimWrapper() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: TASKLIST };
}

export function gotoClaimWrapperCompleted() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: REVIEW };
}

export function gotoMyClaims() {
  return goTo(MY_CLAIMS_PAGE);
}

export function goToMyClaimsPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: MY_CLAIMS_PAGE };
}

export function gotoContactsPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: CONTACTS_PAGE };
}
export function gotoFAQPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: FAQ_PAGE };
}
export function gotoCookiesPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: COOKIES_PAGE };
}
export function gotoDataProtectionPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: DATAPROTECTION_PAGE };
}

export function gotoLegalNoticePage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: LEGALNOTICE_PAGE };
}

export function gotoCheckPolicyCover() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: CHECK_POLICY_COVER };
}

export function goToCoverPage() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: COVER_PAGE };
}
export function goToCoverPageByDate() {
    return { type: types.UPDATE_ACTIVE_PAGE, page: COVER_PAGE_BYDATE };
}

export function goToCheckCoverForm() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: COVER_PAGE_FORM };
}

export function goToGenerateCertificateSuccess() {
  return { type: types.UPDATE_ACTIVE_PAGE, page: GENERATE_CERTIFICATE_SUCCESS };
}

export function gotoUploadClaimDocumentsPage() {
  return {
    type: types.UPDATE_ACTIVE_PAGE,
    page: UPLOAD_CLAIM_DOCUMENTS
  };
}

export function updateActivePage(page) {
  return { type: types.UPDATE_ACTIVE_PAGE, page };
}

export function gotoProfileDashboardPage() {
  return {
    type: types.UPDATE_ACTIVE_PAGE,
    page: PROFILE_DASHBOARD_PAGE
  };
}

export function bodiamAddCardAction() {
  return {
    type: types.UPDATE_BODIAM_ACTION,
    bodiamAction: BODIAM_ACTIONS.ADD_CARD
  };
}

export const bodiamEditProfileAction = () => ({
  type: types.UPDATE_BODIAM_ACTION,
  bodiamAction: BODIAM_ACTIONS.EDIT_PROFILE
});

export const bodiamViewProfileAction = () => ({
  type: types.UPDATE_BODIAM_ACTION,
  bodiamAction: BODIAM_ACTIONS.VIEW_PROFILE
});

export function bodiamMakeClaimAction() {
  return {
    type: types.UPDATE_BODIAM_ACTION,
    bodiamAction: BODIAM_ACTIONS.MAKE_CLAIM
  };
}

export function bodiamStaticPageArundel() {
  return {
    type: types.UPDATE_BODIAM_ACTION,
    bodiamAction: BODIAM_ACTIONS.STATIC_PAGES
  };
}

export function bodiamRemoveCardAction() {
  return {
    type: types.UPDATE_BODIAM_ACTION,
    bodiamAction: BODIAM_ACTIONS.REMOVE_CARD
  };
}

export function bodiamRemovedCardAction() {
  return {
    type: types.UPDATE_BODIAM_ACTION,
    bodiamAction: BODIAM_ACTIONS.REMOVED_CARD
  };
}

export function emptyBodiamAction() {
  return { type: types.UPDATE_BODIAM_ACTION, bodiamAction: null };
}

export function updateBodiamAction(bodiamAction) {
  return { type: types.UPDATE_BODIAM_ACTION, bodiamAction };
}

export const gotoTriageWrapper = () => dispatch => {
  dispatch(resetDecisionTree());
  dispatch(actionProvider.getClaimActions().emptyCurrentClaim());
  dispatch(gotoTriageWrapperClaim());
};

export const gotoLandingPage = () => dispatch => {
  dispatch(resetDecisionTree());
  dispatch(actionProvider.getClaimActions().emptyCurrentClaim());
  dispatch(gotoLandingMainPage());
};
