import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { pageview } from 'utils/analytics';
import { getRoute } from 'utils/routeHelper';
import { bindActionCreators } from 'redux';

import * as activePageActions from 'actions/activePageActions';
import * as authenticationActions from 'actions/authenticationActions';
import { scrollTop } from 'utils/pageUtils';
import history from 'utils/history';
import { getMarketFromUrl,isMarketLufthansa,isMaintenance} from 'utils/urlHelpers';

import LayoutPage from 'components/LayoutPage';
import LandingPage from 'containers/LandingPage';
import SignInPage from 'containers/SignInPage';
import RegistrationPage from 'containers/RegistrationPage';
import TriageWrapper from 'containers/TriageWrapper';
import ProfileDashBoardPage from 'containers/ProfileDashBoardPage';
import RecoverPasswordPage from 'components/RecoverPasswordForm/RecoverPasswordPage';
import Loading from 'components/Loading';
import ClaimWrapper from 'containers/ClaimWrapper';

import FAQPage from 'containers/FAQPage';
import ContactsPage from 'containers/ContactsPage';
import CookiesPage from 'containers/CookiesPage';
import DataProtectionPage from 'containers/DataProtectionPage';
import LegalNoticePage from 'containers/LegalNoticePage';
import MaintenancePage from 'containers/MaintenancePage';
import CheckPolicyCoverPage from 'containers/CheckPolicyCover';
import UploadClaimDocumentPage from 'containers/UploadClaimDocument';
import CheckCoverPage from 'components/CheckCoverModal/CheckCoverPage';
import CheckCoverPageByDate from 'components/CheckCoverModal/CheckCoverPageByDate';
import CheckCoverForm from 'components/CheckCoverModal/CheckCoverForm';
import {
  LANDING_PAGE,
  SIGNIN_PAGE,
  REGISTRATION_PAGE,
  TRIAGE_WRAPPER,
  MY_CLAIMS_PAGE,
  CLAIM_WRAPPER,
  RECOVER_PASSWORD,
  FAQ_PAGE,
  CONTACTS_PAGE,
  COOKIES_PAGE,
  DATAPROTECTION_PAGE,
  LEGALNOTICE_PAGE,
  BODIAM_DASHBOARD,
  BODIAM_YOUR_COVER,
  CHECK_POLICY_COVER,
  UPLOAD_CLAIM_DOCUMENTS,
  BODIAM_BASE_URL,
    COVER_PAGE, COVER_PAGE_BYDATE,
  COVER_PAGE_FORM,
  GENERATE_CERTIFICATE_SUCCESS
} from 'constants/constants';
import GenerateCertificateSuccess from 'components/CheckCoverModal/GenerateCertificateSuccess';
import { appInsights } from 'ApplicationInsights';



export class PageManager extends Component {
    
  constructor(props) {
    super(props);

    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleCustomRouter = this.handleCustomRouter.bind(this);
   
  }

  
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);

    this.handleCustomRouter();

    scrollTop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePage !== this.props.activePage) {
      scrollTop();
    }

    this.handleCustomRouter();
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleCustomRouter() {
    window.addEventListener('popstate', this.handleBackButton);
  }

  handleLogout() {
    this.props.actions.onLogout();
  }

  handleBackButton() {
    const currentHistory = JSON.parse(localStorage.getItem('history'));
    if (currentHistory.length === 1 && currentHistory[0] === LANDING_PAGE) {
      return;
    }
    window.history.forward();
  }

  render() {
    const getActivePage = activePage => {
      const detectClaimWrapperFormat = activePage.split('__')[0];

      if(isMaintenance()=='true'){
        return (<LayoutPage fullWidth actions={this.props.actions}>
          <MaintenancePage />
        </LayoutPage>);
      }
      switch (detectClaimWrapperFormat) {
        case LANDING_PAGE:
          return <LandingPage />;
        case SIGNIN_PAGE:
          return (
            <LayoutPage hasBackground actions={this.props.actions}>
              <SignInPage />
            </LayoutPage>
          );
        case REGISTRATION_PAGE:
          return (
            <LayoutPage hasBackground actions={this.props.actions}>
              <RegistrationPage />
            </LayoutPage>
          );
        case TRIAGE_WRAPPER:
          return (
            <LayoutPage
              Title={this.props.labels.TriageTitle}
              SubTitle={this.props.labels.TriageSubTitle}
              hasBackground
              hasBgColor
              actions={this.props.actions}
            >
              <TriageWrapper />
            </LayoutPage>
          );

        case MY_CLAIMS_PAGE:
          return (
            <ProfileDashBoardPage
              labels={this.props.labels}
              onLogOut={this.handleLogout}
            />
          );

        case RECOVER_PASSWORD:
          return (
            <LayoutPage hasBackground actions={this.props.actions}>
              <RecoverPasswordPage />
            </LayoutPage>
          );

        case CLAIM_WRAPPER:
          return <ClaimWrapper onLogOut={this.handleLogout} />;

        case FAQ_PAGE:
          return (
            <LayoutPage actions={this.props.actions}>
              <FAQPage />
            </LayoutPage>
          );
        case CONTACTS_PAGE:
          return (
            <LayoutPage actions={this.props.actions}>
              <ContactsPage />
            </LayoutPage>
          );
        case COOKIES_PAGE:
          return (
            <LayoutPage actions={this.props.actions}>
              <CookiesPage />
            </LayoutPage>
          );
        case DATAPROTECTION_PAGE:
          return (
            <LayoutPage actions={this.props.actions}>
              <DataProtectionPage />
            </LayoutPage>
          );
        case LEGALNOTICE_PAGE:
          return (
            <LayoutPage actions={this.props.actions}>
              <LegalNoticePage />
            </LayoutPage>
          );          
        case BODIAM_DASHBOARD:
          history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}`);
          break;
        case BODIAM_YOUR_COVER:
          history.push(`${BODIAM_BASE_URL}${getMarketFromUrl()}/your-cover`);
          break;
        case CHECK_POLICY_COVER:
          return (
            <LayoutPage fullWidth actions={this.props.actions}>
              <CheckPolicyCoverPage />
            </LayoutPage>
          );
        case COVER_PAGE:
          return (
            <LayoutPage fullWidth actions={this.props.actions}>
              <CheckCoverPageByDate />
            </LayoutPage>
              );
          case COVER_PAGE_BYDATE:
              return (
                  <LayoutPage fullWidth actions={this.props.actions}>
                      <CheckCoverPage />
                  </LayoutPage>
              );
        case COVER_PAGE_FORM:
          return (
            <LayoutPage fullWidth actions={this.props.actions}>
              <CheckCoverForm />
            </LayoutPage>
          );
        case GENERATE_CERTIFICATE_SUCCESS:
          return (
            <LayoutPage fullWidth actions={this.props.actions}>
              <GenerateCertificateSuccess />
            </LayoutPage>
          );

        case UPLOAD_CLAIM_DOCUMENTS:
          return (
            <LayoutPage fullWidth actions={this.props.actions}>
              <UploadClaimDocumentPage />
            </LayoutPage>
          );
        default:
          pageview({
            title: 'Error',
            page: getRoute('pages', 'genericErrorMessage')
          });
          appInsights.trackPageView(
            {
              name: 'Error',
            uri: getRoute('pages', 'genericErrorMessage')
            }
          )
          return (
            <div className="error">{this.props.labels.GenericErrorMessage}</div>
          );
      }
    };

    const content = getActivePage(this.props.activePage);

    return (
      <div>
        <div className="main-page">{content}</div>
        <div>
          <Loading
            loading={this.props.loading}
            text={this.props.labels.loading}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    activePage: state.activePage,
    labels: state.labels,
    loading: state.ajaxCallsInProgress > 0,
    bodiamAction: state.bodiamAction,
    actions: {
      updateActivePage: state.gotoContactsPage,
      gotoContactsPage: state.gotoContactsPage,
      gotoFAQPage: state.gotoFAQPage,
      gotoCookiesPage: state.gotoCookiesPage,
      gotoDataProtectionPage: state.gotoDataProtectionPage,
      gotoLegalNoticePage: state.gotoLegalNoticePage,
      gotoLandingMainPage: state.gotoLandingMainPage,
      getPolicyDetails: state.getPolicyDetails
    }
  };
}

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(activePageActions, dispatch),
    ...bindActionCreators(authenticationActions, dispatch)
  }
});

PageManager.propTypes = {
  loading: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    GenericErrorMessage: PropTypes.string,
    claimSaverContantPhone: PropTypes.string,
    TriageSubTitle: PropTypes.string,
    TriageTitle: PropTypes.string,
    stepHappenTitle: PropTypes.string,
    loading: PropTypes.string
  }).isRequired,
  activePage: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onLogout: PropTypes.func.isRequired,
    updateActivePage: PropTypes.func.isRequired,
    gotoContactsPage: PropTypes.func.isRequired,
    gotoFAQPage: PropTypes.func.isRequired,
    gotoCookiesPage: PropTypes.func.isRequired,
    gotoLandingMainPage: PropTypes.func.isRequired,
    getPolicyDetails: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(PageManager);
